import React from 'react';

import { Box } from '@mui/material';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { scrollToSpecialHeight } from '../../utils';

import { DocumentParts, SHOW_UP_TO_TOP_STRESS_HEIGHT } from './constants';
import {
  BoldStyledLink,
  LastUpdatedText,
  List,
  ListItemWithOutPadding,
  PageTitle,
  ParagraphContent,
  SmallParagraphTitle,
} from './styled';

interface CookieNoticeProps {
  setActiveLink: (newDocumentPart: DocumentParts) => void;
}

const CookieNoticePage = ({ setActiveLink }: CookieNoticeProps) => {
  const { t } = useTranslation();

  const onSelectedLink = (selectedLink: DocumentParts) => {
    setActiveLink(selectedLink);
    window.location.hash = selectedLink;
    scrollToSpecialHeight(SHOW_UP_TO_TOP_STRESS_HEIGHT);
  };

  const handleSelectedLink = () => onSelectedLink(DocumentParts.PrivacyPolicy);

  return (
    <Box>
      <PageTitle>{t('legalDocumentsPages.cookieNotice.title')}</PageTitle>
      <LastUpdatedText>{t('legalDocumentsPages.lastUpdateInfo')}</LastUpdatedText>
      <SmallParagraphTitle mt="60px">{t('legalDocumentsPages.cookieNotice.weUseCookies.title')}</SmallParagraphTitle>
      <ParagraphContent mt="20px">{t('legalDocumentsPages.cookieNotice.weUseCookies.firstParagraph')}</ParagraphContent>
      <SmallParagraphTitle mt="20px">
        {t('legalDocumentsPages.cookieNotice.acceptYourTrackingPreferences.title')}
      </SmallParagraphTitle>
      <ParagraphContent margin="20px 0">
        {t('legalDocumentsPages.cookieNotice.acceptYourTrackingPreferences.subtitle')}
      </ParagraphContent>
      <List>
        <ListItemWithOutPadding>
          <ParagraphContent>
            {t('legalDocumentsPages.cookieNotice.acceptYourTrackingPreferences.unorderedList.firstListItem')}
          </ParagraphContent>
        </ListItemWithOutPadding>
      </List>
      <ParagraphContent margin="20px 0">
        {t('legalDocumentsPages.cookieNotice.acceptYourTrackingPreferences.firstParagraph')}
      </ParagraphContent>
      <List>
        <ListItemWithOutPadding>
          <ParagraphContent>
            {t('legalDocumentsPages.cookieNotice.acceptYourTrackingPreferences.unorderedList.secondListItem')}
          </ParagraphContent>
        </ListItemWithOutPadding>
      </List>
      <ParagraphContent margin="20px 0">
        {t('legalDocumentsPages.cookieNotice.acceptYourTrackingPreferences.secondParagraph')}
      </ParagraphContent>
      <List>
        <ListItemWithOutPadding>
          <ParagraphContent>
            {t('legalDocumentsPages.cookieNotice.acceptYourTrackingPreferences.unorderedList.thirdListItem')}
          </ParagraphContent>
        </ListItemWithOutPadding>
      </List>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.cookieNotice.acceptYourTrackingPreferences.thirdParagraph')}
      </ParagraphContent>
      <SmallParagraphTitle mt="20px">{t('legalDocumentsPages.cookieNotice.learnMore')}</SmallParagraphTitle>
      <SmallParagraphTitle>
        {t('legalDocumentsPages.cookieNotice.checkOutOur')}
        <BoldStyledLink onClick={handleSelectedLink}>
          {t('legalDocumentsPages.cookieNotice.privacyPolicyLinkContent')}
        </BoldStyledLink>
        .
      </SmallParagraphTitle>
    </Box>
  );
};

export default CookieNoticePage;
