import { graphql } from 'gatsby';

import DocumentsPage from '../components/DocumentsPage';
import withGlobalStyles from '../components/GlobalStyleHOC';

export const query = graphql`
  query {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default withGlobalStyles(DocumentsPage);
