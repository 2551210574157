import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Link } from 'react-scroll';

import { Collapse } from '@mui/material';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import Iconography from 'src/components/common/Iconography';
import { DeviceSizes } from 'src/components/Landing/types';
import { useWindowWidth } from 'src/hooks/useWindowWidth';
import { scrollToSpecialHeight, theBottomOfTheElementisVisible } from 'src/utils';

import {
  DocumentParts,
  SHOW_UP_TO_TOP_STRESS_HEIGHT,
  SPACE_BETWEEN_HEADER_AND_SIDEBAR,
  BOTTOM_MARGIN_OF_THE_SIDEBER,
} from './constants';
import { cookiePolicyData, privacyPolicyData, termsAndConditionsData } from './sidebarData';
import {
  MainSidebarLink,
  StyledPositionSidebar,
  StyledSidebarWrapper,
  StyledWrapperList,
  StyledArrowIcon,
  StyledMainLinkBlock,
  StyledLinkBlock,
} from './styled';

import { DocumentsPartsProps } from '.';

interface SidebarProps {
  activeLink: DocumentsPartsProps;
  setActiveLink: (newDocumentPart: DocumentParts) => void;
}

const Sidebar = ({ activeLink, setActiveLink }: SidebarProps) => {
  const { t } = useTranslation();
  const windowWidth = useWindowWidth();
  const positionSidebar = useRef<HTMLElement>(null);

  const [isSidebarFixed, setIsSidebarFixed] = useState<boolean>(false);
  const [heightSidebar, setHeightSidebar] = useState<number>(0);

  const isCurrentLinkPrivacyPolicy = activeLink.expandedContent === DocumentParts.PrivacyPolicy;
  const isCurrentLinkTermsAndCondition = activeLink.expandedContent === DocumentParts.TermsAndConditions;
  const isCurrentLinkCookiePolicy = activeLink.expandedContent === DocumentParts.CookiePolicy;

  const onMainSidebarLinkClick = (selectedLink: DocumentParts) => {
    setActiveLink(selectedLink);
    window.location.hash = selectedLink;

    if (selectedLink !== activeLink.showDocumentsContent && windowWidth >= DeviceSizes.mobileVerticalOrientitaion) {
      scrollToSpecialHeight(SHOW_UP_TO_TOP_STRESS_HEIGHT);
    }
  };

  const handleFixedSidebar = useCallback(() => {
    const headerContent = document.getElementById('header-content');
    const wrapperContent = document.getElementById('wrapperContent');

    if (headerContent) {
      const spaceUpToSidebar = headerContent?.offsetHeight + SPACE_BETWEEN_HEADER_AND_SIDEBAR;

      setIsSidebarFixed(window.scrollY > spaceUpToSidebar);
    }

    if (positionSidebar.current && wrapperContent) {
      const sideBarRect = positionSidebar.current.getBoundingClientRect();
      const wrapperContentRect = wrapperContent.getBoundingClientRect();

      const sidebarHeight = theBottomOfTheElementisVisible(wrapperContent, BOTTOM_MARGIN_OF_THE_SIDEBER)
        ? wrapperContentRect.bottom - sideBarRect.top
        : window.innerHeight - sideBarRect?.top - BOTTOM_MARGIN_OF_THE_SIDEBER;

      setHeightSidebar(sidebarHeight);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('scroll', handleFixedSidebar);

    return () => document.removeEventListener('scroll', handleFixedSidebar);
  }, [handleFixedSidebar]);

  useEffect(() => {
    const sideBarRect = positionSidebar.current?.getBoundingClientRect();

    if (sideBarRect) {
      setHeightSidebar(window.innerHeight - sideBarRect?.top - BOTTOM_MARGIN_OF_THE_SIDEBER);
    }
  }, []);

  return (
    <StyledSidebarWrapper>
      <StyledPositionSidebar ref={positionSidebar} isSidebarFixed={isSidebarFixed} heightBlock={heightSidebar}>
        <StyledLinkBlock>
          <MainSidebarLink
            activelink={activeLink.showDocumentsContent === DocumentParts.PrivacyPolicy ? 'underline' : 'none'}
            onClick={() => onMainSidebarLinkClick(DocumentParts.PrivacyPolicy)}
          >
            {t('legalDocumentsPages.privacyPolicy.title')}
          </MainSidebarLink>
          <StyledArrowIcon>
            <Iconography iconName="arrow-down" htmlColor="text.primary" />
          </StyledArrowIcon>
        </StyledLinkBlock>

        {isCurrentLinkPrivacyPolicy && (
          <Collapse in={isCurrentLinkPrivacyPolicy}>
            <StyledWrapperList>
              {privacyPolicyData.map(({ title, id }) => (
                <Link key={id} to={id} smooth={true} offset={-110}>
                  {t(title)}
                </Link>
              ))}
            </StyledWrapperList>
          </Collapse>
        )}

        <StyledMainLinkBlock>
          <MainSidebarLink
            activelink={activeLink.showDocumentsContent === DocumentParts.TermsAndConditions ? 'underline' : 'none'}
            onClick={() => onMainSidebarLinkClick(DocumentParts.TermsAndConditions)}
          >
            {t('legalDocumentsPages.termsAndConditions.title')}
          </MainSidebarLink>
          <StyledArrowIcon>
            <Iconography iconName="arrow-down" htmlColor="text.primary" />
          </StyledArrowIcon>
        </StyledMainLinkBlock>

        {isCurrentLinkTermsAndCondition && (
          <Collapse in={isCurrentLinkTermsAndCondition}>
            <StyledWrapperList>
              {termsAndConditionsData.map(({ title, id }) => (
                <Link key={id} to={id} smooth offset={-110}>
                  {t(title)}
                </Link>
              ))}
            </StyledWrapperList>
          </Collapse>
        )}

        <StyledMainLinkBlock>
          <MainSidebarLink
            activelink={activeLink.showDocumentsContent === DocumentParts.CookieNotice ? 'underline' : 'none'}
            onClick={() => onMainSidebarLinkClick(DocumentParts.CookieNotice)}
          >
            {t('legalDocumentsPages.cookieNotice.title')}
          </MainSidebarLink>
          <StyledArrowIcon>
            <Iconography iconName="arrow-down" htmlColor="text.primary" />
          </StyledArrowIcon>
        </StyledMainLinkBlock>

        <StyledMainLinkBlock>
          <MainSidebarLink
            activelink={activeLink.showDocumentsContent === DocumentParts.CookiePolicy ? 'underline' : 'none'}
            onClick={() => onMainSidebarLinkClick(DocumentParts.CookiePolicy)}
          >
            {t('legalDocumentsPages.cookiePolicy.title')}
          </MainSidebarLink>
          <StyledArrowIcon>
            <Iconography iconName="arrow-down" htmlColor="text.primary" />
          </StyledArrowIcon>
        </StyledMainLinkBlock>

        {isCurrentLinkCookiePolicy && (
          <Collapse in={isCurrentLinkCookiePolicy}>
            <StyledWrapperList>
              {cookiePolicyData.map(({ title, id }) => (
                <Link key={id} to={id} smooth={true} offset={-110}>
                  {t(title)}
                </Link>
              ))}
            </StyledWrapperList>
          </Collapse>
        )}
      </StyledPositionSidebar>
    </StyledSidebarWrapper>
  );
};

export default Sidebar;
