export const privacyPolicyData = [
  { id: 'introduction', title: 'legalDocumentsPages.privacyPolicy.introduction.title' },
  { id: 'collectionYourInformation', title: 'legalDocumentsPages.privacyPolicy.collectionYourInformation.title' },
  { id: 'useYourInformation', title: 'legalDocumentsPages.privacyPolicy.useYourInformation.title' },
  { id: 'disclosureYourInformation', title: 'legalDocumentsPages.privacyPolicy.disclosureYourInformation.title' },
  { id: 'trackingTechnologies', title: 'legalDocumentsPages.privacyPolicy.trackingTechnologies.title' },
  { id: 'thirdPartyWebsites', title: 'legalDocumentsPages.privacyPolicy.thirdPartyWebsites.title' },
  { id: 'securityOfYourInformation', title: 'legalDocumentsPages.privacyPolicy.securityOfYourInformation.title' },
  { id: 'placeForChildren', title: 'legalDocumentsPages.privacyPolicy.placeForChildren.title' },
  {
    id: 'controlsForDoNotTrackFeatures',
    title: 'legalDocumentsPages.privacyPolicy.controlsForDoNotTrackFeatures.title',
  },
  {
    id: 'optionsRegardingYourInformation',
    title: 'legalDocumentsPages.privacyPolicy.optionsRegardingYourInformation.title',
  },
  { id: 'californiaPrivacyRights', title: 'legalDocumentsPages.privacyPolicy.californiaPrivacyRights.title' },
  { id: 'contactUsPrivacyPolicy', title: 'legalDocumentsPages.privacyPolicy.contactUs.title' },
];

export const termsAndConditionsData = [
  { title: 'legalDocumentsPages.termsAndConditions.agreementToTerms.title', id: 'agreementToTerms' },
  {
    title: 'legalDocumentsPages.termsAndConditions.intellectualPropertyRights.title',
    id: 'intellectualPropertyRights',
  },
  {
    title: 'legalDocumentsPages.termsAndConditions.userRepresentations.title',
    id: 'userRepresentations',
  },
  { title: 'legalDocumentsPages.termsAndConditions.userRegistration.title', id: 'userRegistration' },
  {
    title: 'legalDocumentsPages.termsAndConditions.prohibitedActivities.title',
    id: 'prohibitedActivities',
  },
  {
    title: 'legalDocumentsPages.termsAndConditions.userGeneratedContributions.title',
    id: 'userGeneratedContributions',
  },
  {
    title: 'legalDocumentsPages.termsAndConditions.contributionLicense.title',
    id: 'contributionLicense',
  },
  {
    title: 'legalDocumentsPages.termsAndConditions.guidelinesForReviews.title',
    id: 'guidelinesForReviews',
  },
  { title: 'legalDocumentsPages.termsAndConditions.socialMedia.title', id: 'socialMedia' },
  { title: 'legalDocumentsPages.termsAndConditions.submissions.title', id: 'submissions' },
  {
    title: 'legalDocumentsPages.termsAndConditions.thirdPartyWebsites.title',
    id: 'thirdPartyWebsites',
  },
  { title: 'legalDocumentsPages.termsAndConditions.advertisers.title', id: 'advertisers' },
  {
    title: 'legalDocumentsPages.termsAndConditions.siteManagement.title',
    id: 'siteManagement',
  },
  {
    title: 'legalDocumentsPages.termsAndConditions.privacyPolicy.title',
    id: 'privacyPolicy',
  },
  { title: 'legalDocumentsPages.termsAndConditions.DMCA.title', id: 'DMCA' },
  {
    title: 'legalDocumentsPages.termsAndConditions.copyrightInfringements.title',
    id: 'copyrightInfringements',
  },
  {
    title: 'legalDocumentsPages.termsAndConditions.termAndTermination.title',
    id: 'termAndTermination',
  },
  {
    title: 'legalDocumentsPages.termsAndConditions.modificationsAndInterruptions.title',
    id: 'modificationsAndInterruptions',
  },
  { title: 'legalDocumentsPages.termsAndConditions.governingLaw.title', id: 'governingLaw' },
  {
    title: 'legalDocumentsPages.termsAndConditions.disputeResolution.title',
    id: 'disputeResolution',
  },
  { title: 'legalDocumentsPages.termsAndConditions.corrections.title', id: 'corrections' },
  { title: 'legalDocumentsPages.termsAndConditions.disclaimer.title', id: 'disclaimer' },
  { title: 'legalDocumentsPages.termsAndConditions.limitations.title', id: 'limitations' },
  {
    title: 'legalDocumentsPages.termsAndConditions.indemnification.title',
    id: 'indemnification',
  },
  { title: 'legalDocumentsPages.termsAndConditions.userData.title', id: 'userData' },
  {
    title: 'legalDocumentsPages.termsAndConditions.communications.title',
    id: 'communications',
  },
  {
    title: 'legalDocumentsPages.termsAndConditions.userAndResidents.title',
    id: 'userAndResidents',
  },
  {
    title: 'legalDocumentsPages.termsAndConditions.miscellaneous.title',
    id: 'miscellaneous',
  },
  { title: 'legalDocumentsPages.termsAndConditions.contactUs.title', id: 'contactUsTermsAndConditions' },
];

export const cookiePolicyData = [
  { title: 'legalDocumentsPages.cookiePolicy.introduction.title', id: 'introduction' },
  { title: 'legalDocumentsPages.cookiePolicy.useOfCookies.title', id: 'useOfCookies' },
  { title: 'legalDocumentsPages.cookiePolicy.typesOfCookies.title', id: 'typesOfCookies' },
  {
    title: 'legalDocumentsPages.cookiePolicy.controlOfCookies.title',
    id: 'controlOfCookies',
  },
  {
    title: 'legalDocumentsPages.cookiePolicy.otherTrackingTechnologies.title',
    id: 'otherTrackingTechnologies',
  },
  { title: 'legalDocumentsPages.cookiePolicy.privacyPolicy.title', id: 'privacyPolicyTitle' },
  { title: 'legalDocumentsPages.cookiePolicy.contactUs.title', id: 'contactUsCookiePolicy' },
];
