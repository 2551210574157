import React from 'react';

import { Box } from '@mui/material';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { scrollToSpecialHeight } from '../../utils';

import { DocumentParts, SHOW_UP_TO_TOP_STRESS_HEIGHT } from './constants';
import {
  LastUpdatedText,
  PageTitle,
  ParagraphContent,
  ParagraphTitle,
  SmallParagraphTitle,
  StyledLink,
  StyledLinkWithTopPadding,
} from './styled';

interface CookiePolicyProps {
  setActiveLink: (newDocumentPart: DocumentParts) => void;
}

const CookiePolicyPage = ({ setActiveLink }: CookiePolicyProps) => {
  const { t } = useTranslation();

  const onSelectedLink = (selectedLink: DocumentParts) => {
    setActiveLink(selectedLink);
    window.location.hash = selectedLink;
    scrollToSpecialHeight(SHOW_UP_TO_TOP_STRESS_HEIGHT);
  };

  const handleSelectedLink = () => onSelectedLink(DocumentParts.PrivacyPolicy);

  return (
    <Box>
      <PageTitle>{t('legalDocumentsPages.cookiePolicy.title')}</PageTitle>
      <LastUpdatedText>{t('legalDocumentsPages.lastUpdateInfo')}</LastUpdatedText>
      <ParagraphTitle id="introduction">{t('legalDocumentsPages.cookiePolicy.introduction.title')}</ParagraphTitle>
      <ParagraphContent mt="30px">{t('legalDocumentsPages.cookiePolicy.introduction.firstParagraph')}</ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.cookiePolicy.introduction.secondParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">{t('legalDocumentsPages.cookiePolicy.introduction.thirdParagraph')}</ParagraphContent>
      <ParagraphTitle id="useOfCookies">{t('legalDocumentsPages.cookiePolicy.useOfCookies.title')}</ParagraphTitle>
      <ParagraphContent mt="30px">{t('legalDocumentsPages.cookiePolicy.useOfCookies.firstParagraph')}</ParagraphContent>
      <ParagraphTitle id="typesOfCookies">{t('legalDocumentsPages.cookiePolicy.typesOfCookies.title')}</ParagraphTitle>
      <ParagraphContent mt="30px">
        {t('legalDocumentsPages.cookiePolicy.typesOfCookies.firstParagraph')}
      </ParagraphContent>
      <SmallParagraphTitle mt="20px">
        {t('legalDocumentsPages.cookiePolicy.typesOfCookies.advertisingCookie.title')}
      </SmallParagraphTitle>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.cookiePolicy.typesOfCookies.advertisingCookie.firstParagraph')}
      </ParagraphContent>
      <SmallParagraphTitle mt="20px">
        {t('legalDocumentsPages.cookiePolicy.typesOfCookies.analyticsCookies.title')}
      </SmallParagraphTitle>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.cookiePolicy.typesOfCookies.analyticsCookies.firstParagraph')}
      </ParagraphContent>
      <SmallParagraphTitle mt="20px">
        {t('legalDocumentsPages.cookiePolicy.typesOfCookies.ourCookies.title')}
      </SmallParagraphTitle>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.cookiePolicy.typesOfCookies.ourCookies.firstParagraph')}
      </ParagraphContent>
      <SmallParagraphTitle mt="20px">
        {t('legalDocumentsPages.cookiePolicy.typesOfCookies.personalizationCookies.title')}
      </SmallParagraphTitle>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.cookiePolicy.typesOfCookies.personalizationCookies.firstParagraph')}
      </ParagraphContent>
      <SmallParagraphTitle mt="20px">
        {t('legalDocumentsPages.cookiePolicy.typesOfCookies.securityCookies.title')}
      </SmallParagraphTitle>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.cookiePolicy.typesOfCookies.securityCookies.firstParagraph')}
      </ParagraphContent>
      <SmallParagraphTitle mt="20px">
        {t('legalDocumentsPages.cookiePolicy.typesOfCookies.siteManagementCookies.title')}
      </SmallParagraphTitle>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.cookiePolicy.typesOfCookies.siteManagementCookies.firstParagraph')}
      </ParagraphContent>
      <SmallParagraphTitle mt="20px">
        {t('legalDocumentsPages.cookiePolicy.typesOfCookies.thirdPartyCookies.title')}
      </SmallParagraphTitle>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.cookiePolicy.typesOfCookies.thirdPartyCookies.firstParagraph')}
      </ParagraphContent>
      <ParagraphTitle id="controlOfCookies">
        {t('legalDocumentsPages.cookiePolicy.controlOfCookies.title')}
      </ParagraphTitle>
      <ParagraphContent mt="30px">
        {t('legalDocumentsPages.cookiePolicy.controlOfCookies.firstParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.cookiePolicy.controlOfCookies.secondParagraph')}
      </ParagraphContent>
      <Box display="flex" flexDirection="column">
        <StyledLinkWithTopPadding href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac">
          {t('legalDocumentsPages.cookiePolicy.controlOfCookies.links.safari')}
        </StyledLinkWithTopPadding>
        <StyledLinkWithTopPadding href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en">
          {t('legalDocumentsPages.cookiePolicy.controlOfCookies.links.chrome')}
        </StyledLinkWithTopPadding>
        <StyledLinkWithTopPadding href="https://support.microsoft.com/en-us/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd">
          {t('legalDocumentsPages.cookiePolicy.controlOfCookies.links.edge')}
        </StyledLinkWithTopPadding>
        <StyledLinkWithTopPadding href="https://support.microsoft.com/en-gb/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d">
          {t('legalDocumentsPages.cookiePolicy.controlOfCookies.links.explorer')}
        </StyledLinkWithTopPadding>
        <StyledLinkWithTopPadding href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US">
          {t('legalDocumentsPages.cookiePolicy.controlOfCookies.links.firefox')}
        </StyledLinkWithTopPadding>
        <StyledLinkWithTopPadding href="https://help.opera.com/en/latest/">
          {t('legalDocumentsPages.cookiePolicy.controlOfCookies.links.opera')}
        </StyledLinkWithTopPadding>
        <StyledLinkWithTopPadding href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&hl=en&oco=1">
          {t('legalDocumentsPages.cookiePolicy.controlOfCookies.links.androidChrome')}
        </StyledLinkWithTopPadding>
        <StyledLinkWithTopPadding href="https://docs.blackberry.com/content/dam/docs-blackberry-com/release-pdfs/en/device-user-guides/BlackBerry-Classic-Smartphone-10.3.3-User-Guide-en.pdf">
          {t('legalDocumentsPages.cookiePolicy.controlOfCookies.links.blackBerry')}
        </StyledLinkWithTopPadding>
        <StyledLinkWithTopPadding href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DiOS&hl=en&oco=1">
          {t('legalDocumentsPages.cookiePolicy.controlOfCookies.links.iosChrome')}
        </StyledLinkWithTopPadding>
        <StyledLinkWithTopPadding href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&hl=en&oco=1">
          {t('legalDocumentsPages.cookiePolicy.controlOfCookies.links.iosSafari')}
        </StyledLinkWithTopPadding>
        <ParagraphContent mt="20px">
          {t('legalDocumentsPages.cookiePolicy.controlOfCookies.beforeLastLinkContent')}
          <StyledLink href="https://optout.networkadvertising.org/?c=1">
            {t('legalDocumentsPages.cookiePolicy.controlOfCookies.links.optOutTool')}
          </StyledLink>
          .
        </ParagraphContent>
      </Box>
      <ParagraphTitle id="otherTrackingTechnologies">
        {t('legalDocumentsPages.cookiePolicy.otherTrackingTechnologies.title')}
      </ParagraphTitle>
      <ParagraphContent mt="30px">
        {t('legalDocumentsPages.cookiePolicy.otherTrackingTechnologies.firstParagraph')}
      </ParagraphContent>
      <ParagraphTitle id="privacyPolicyTitle">
        {t('legalDocumentsPages.cookiePolicy.privacyPolicy.title')}
      </ParagraphTitle>
      <ParagraphContent mt="30px">
        {t('legalDocumentsPages.cookiePolicy.privacyPolicy.firstParagraphBeforeLink')}
        <StyledLink onClick={handleSelectedLink}>
          {t('legalDocumentsPages.cookiePolicy.privacyPolicy.linkContent')}
        </StyledLink>
        {t('legalDocumentsPages.cookiePolicy.privacyPolicy.firstParagraphAfterLink')}
      </ParagraphContent>
      <ParagraphTitle id="contactUsCookiePolicy">
        {t('legalDocumentsPages.cookiePolicy.contactUs.title')}
      </ParagraphTitle>
      <ParagraphContent mt="30px">{t('legalDocumentsPages.cookiePolicy.contactUs.firstParagraph')}</ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.cookiePolicy.contactUs.secondParagraph')}
        <StyledLink href="mailto:support@otomate.shop">
          {t('legalDocumentsPages.cookiePolicy.contactUs.linkContent')}
        </StyledLink>
      </ParagraphContent>
    </Box>
  );
};

export default CookiePolicyPage;
