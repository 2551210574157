import React from 'react';
import { Helmet } from 'react-helmet-async';

import { useStaticQuery, graphql } from 'gatsby';

import { STATIC_SEO_TITLE } from 'src/constants';

interface Props {
  title: string;
  description?: string;
  lang?: string;
  meta?: { [key: string]: string }[];
  script?: string;
}

const Seo = ({ title, description, lang, meta = [], script }: Props) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;

  return (
    <Helmet
      prioritizeSeoTags
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${defaultTitle || STATIC_SEO_TITLE}`}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata?.author || '',
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        ...meta,
      ]}
    >
      {script && <script type="application/ld+json">{script}</script>}
    </Helmet>
  );
};

export default Seo;
