import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Header from 'src/components/Header/Header';
import Footer from 'src/components/Landing/Footer/Footer';
import Seo from 'src/components/SEO/SEO';
import { scrollToSpecialHeight } from 'src/utils';

import { DocumentParts, SHOW_UP_TO_TOP_STRESS_HEIGHT } from './constants';
import CookieNoticePage from './CookieNoticePage';
import CookiePolicyPage from './CookiePolicyPage';
import PrivacyPolicyPage from './PrivacyPolicyPage';
import Sidebar from './Sidebar';
import {
  StyledDocumentsPageWrapper,
  StyledWrapperContent,
  StyledWrapperDocumentsContent,
  StyledHeader,
} from './styled';
import TermsAndConditionsPage from './TermsAndConditionsPage';

export interface DocumentsPartsProps {
  expandedContent?: DocumentParts;
  showDocumentsContent?: DocumentParts;
}

const DocumentsPage = () => {
  const { t } = useTranslation();

  const [activeLink, setActiveLink] = useState<DocumentsPartsProps>({});

  const handleActivateDocumentPart = (newDocumentPart: DocumentParts) => {
    setActiveLink((prev) => ({
      showDocumentsContent: newDocumentPart,
      expandedContent: newDocumentPart === prev.expandedContent ? undefined : newDocumentPart,
    }));
  };

  useEffect(() => {
    setTimeout(() => {
      scrollToSpecialHeight(SHOW_UP_TO_TOP_STRESS_HEIGHT);
    }, 0);
  }, []);

  useEffect(() => {
    if (!window.location.hash) {
      handleActivateDocumentPart(DocumentParts.PrivacyPolicy);
    } else {
      handleActivateDocumentPart(window.location.hash as DocumentParts);
    }
  }, []);

  return (
    <StyledDocumentsPageWrapper>
      <Seo title="Documents" />
      <StyledHeader>
        <Header title={t('legalDocumentsPages.headerTitle') as string} />
      </StyledHeader>

      <StyledWrapperDocumentsContent>
        <Sidebar activeLink={activeLink} setActiveLink={handleActivateDocumentPart} />

        <StyledWrapperContent id="wrapperContent">
          {activeLink.showDocumentsContent === DocumentParts.PrivacyPolicy ? (
            <PrivacyPolicyPage />
          ) : activeLink.showDocumentsContent === DocumentParts.CookiePolicy ? (
            <CookiePolicyPage setActiveLink={handleActivateDocumentPart} />
          ) : activeLink.showDocumentsContent === DocumentParts.CookieNotice ? (
            <CookieNoticePage setActiveLink={handleActivateDocumentPart} />
          ) : (
            activeLink.showDocumentsContent === DocumentParts.TermsAndConditions && <TermsAndConditionsPage />
          )}
        </StyledWrapperContent>
      </StyledWrapperDocumentsContent>

      <Footer setActiveLink={handleActivateDocumentPart} />
    </StyledDocumentsPageWrapper>
  );
};

export default DocumentsPage;
