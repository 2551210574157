import { Box, styled, Typography } from '@mui/material';
import { Link } from 'gatsby';

interface SidebarProps {
  isSidebarFixed: boolean;
  heightBlock: number;
}

interface LinkProps {
  activelink?: string;
}

export const MainSidebarLink = styled(Typography)<LinkProps>(({ theme, activelink }) => ({
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '18px',
  lineHeight: '21px',
  cursor: 'pointer',
  color: theme.palette.text.primary,
  textDecoration: activelink,

  [theme.breakpoints.down('tabletS')]: {
    textDecoration: 'none',
  },
}));

export const HeaderLink = styled(Link)`
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
  padding: 15px;
  color: ${({ theme: { palette } }) => palette.common.white};

  &:hover {
    cursor: pointer;
    color: ${({ theme: { palette } }) => palette.info.main};
  }
`;

export const MainTitle = styled(Typography)(({ theme }) => ({
  padding: '78px 0 180px',
  fontWeight: 700,
  fontSize: '58px',
  lineHeight: '66px',
  textAlign: 'center',
  color: theme.palette.text.primary,
  zIndex: 1,
  position: 'relative',
}));

export const ParagraphTitle = styled(Typography)`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 42px;
  color: ${({ theme: { palette } }) => palette.text.primary};
  margin-top: 60px;
`;

export const StyledLink = styled('a')`
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: ${({ theme: { palette } }) => palette.text.primary};
  max-width: 820px;
  cursor: pointer;

  :hover {
    color: ${({ theme: { palette } }) => palette.info.light};
  }
`;

export const StyledWrapperHeader = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.success.main}`,
}));

export const StyledWrapperContent = styled(Box)`
  max-width: 820px;
  width: 100%;
`;

export const PageHeader = styled(Box)`
  margin: 0 auto;
  position: relative;
  max-width: 1200px;
  width: 100%;
`;

export const PageTitle = styled(Typography)`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  max-width: 820px;
  color: ${({ theme: { palette } }) => palette.text.primary};
`;

export const LastUpdatedText = styled(Typography)`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  max-width: 820px;
  margin-top: 20px;

  color: ${({ theme: { palette } }) => palette.text.primary};
  opacity: 0.2;
`;

export const ParagraphContent = styled(Typography)`
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  max-width: 820px;
`;

export const SmallParagraphTitle = styled(Typography)`
  font-size: 24px;
  line-height: 28px;
  max-width: 820px;
`;

export const BoldStyledLink = styled(StyledLink)`
  font-weight: bold;
  font-size: 24px;
  max-width: 820px;
`;

export const HeaderLinkWithRightPadding = styled(HeaderLink)`
  margin-right: 100px;
  max-width: 820px;
`;

export const StyledLinkWithTopPadding = styled(StyledLink)`
  margin-top: 20px;
  max-width: 820px;
`;

export const List = styled('ul')`
  padding-left: 19px;
  max-width: 820px;
`;

export const ListItem = styled('li')`
  margin-top: 20px;
  max-width: 820px;
`;

export const ListItemWithOutPadding = styled(ListItem)`
  margin-top: 0;
`;

export const StyledDocumentsPageWrapper = styled(Box)`
  background-color: ${({ theme: { palette } }) => palette.background.default};
`;

export const StyledWrapperDocumentsContent = styled(Box)(({ theme }) => ({
  maxWidth: '1248px',
  margin: '0 auto 180px',
  padding: '0 24px',
  display: 'flex',
  justifyContent: 'space-between',

  [theme.breakpoints.down('tabletS')]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export const StyledWrapperList = styled(Box)(({ theme }) => ({
  borderLeft: `1px solid ${theme.palette.success.main}`,
  margin: '32px 10px 0 0',
  paddingLeft: '20px',
  display: 'flex',
  flexDirection: 'column',

  a: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '18px',
    cursor: 'pointer',
    width: 'fit-content',
  },

  'a:not(:last-child)': {
    paddingBottom: '18px',
  },
}));

export const StyledMainLinkBlock = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '42px',

  [theme.breakpoints.down('tabletS')]: {
    marginTop: '27px',
  },
}));

export const StyledSidebarWrapper = styled(Box)(({ theme }) => ({
  zIndex: 1,
  width: '408px',
  display: 'block',
  marginRight: '50px',

  [theme.breakpoints.down('tabletS')]: {
    margin: '0 0 66px',
    width: '100%',
    maxWidth: '350px',
  },
}));

export const StyledArrowIcon = styled(Box)(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.down('tabletS')]: {
    display: 'flex',
  },
}));

export const StyledPositionSidebar = styled(Box)<SidebarProps>(({ isSidebarFixed, heightBlock, theme }) => ({
  position: isSidebarFixed ? 'fixed' : 'static',
  height: heightBlock || 'auto',
  maxWidth: '260px',
  width: '100%',
  top: '122px',
  overflow: 'hidden',

  '&:hover': {
    overflow: 'auto',
  },

  '&::-webkit-scrollbar': {
    width: '4px',
  },

  '&::-webkit-scrollbar-thumb': {
    borderRadius: '5px',
    background: 'rgba(16, 16, 45, 0.2)',
    boxShadow: 'none',
  },

  [theme.breakpoints.down('tabletM')]: {
    maxWidth: '220px',
  },

  [theme.breakpoints.down('tabletS')]: {
    position: 'static',
    height: '100%',
    maxWidth: '350px',
  },
}));

export const StyledHeader = styled(Box)(({ theme }) => ({
  marginBottom: '100px',

  [theme.breakpoints.down('tabletS')]: {
    marginBottom: '49px',
  },
}));

export const StyledLinkBlock = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const StyledSpatialBlock = styled(Box)(() => ({
  display: 'block',
  borderTop: '122px solid transparent',
  marginTop: '-122px',
  MozBackgroundClip: 'padding',
  WebkitBackgroundClip: 'padding-box',
  backgroundClip: 'padding-box',
}));
