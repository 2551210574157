import React from 'react';

import { useTranslation } from 'gatsby-plugin-react-i18next';

import {
  PageTitle,
  ParagraphTitle,
  ParagraphContent,
  SmallParagraphTitle,
  LastUpdatedText,
  StyledLink,
  List,
  ListItem,
  ListItemWithOutPadding,
  StyledSpatialBlock,
} from './styled';

const PrivacyPolicyPage = () => {
  const { t } = useTranslation();

  return (
    <StyledSpatialBlock id="privacy-policy">
      <PageTitle>{t('legalDocumentsPages.privacyPolicy.title')}</PageTitle>
      <LastUpdatedText>{t('legalDocumentsPages.lastUpdateInfo')}</LastUpdatedText>
      <ParagraphTitle id="introduction">{t('legalDocumentsPages.privacyPolicy.introduction.title')}</ParagraphTitle>
      <ParagraphContent mt="30px">
        {t('legalDocumentsPages.privacyPolicy.introduction.firstParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.privacyPolicy.introduction.secondParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.privacyPolicy.introduction.thirdParagraph')}
      </ParagraphContent>
      <ParagraphTitle id="collectionYourInformation">
        {t('legalDocumentsPages.privacyPolicy.collectionYourInformation.title')}
      </ParagraphTitle>
      <ParagraphContent mt="30px">
        {t('legalDocumentsPages.privacyPolicy.collectionYourInformation.firstParagraph')}
      </ParagraphContent>
      <SmallParagraphTitle mt="20px">
        {t('legalDocumentsPages.privacyPolicy.collectionYourInformation.personalData.title')}
      </SmallParagraphTitle>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.privacyPolicy.collectionYourInformation.personalData.firstParagraph')}
      </ParagraphContent>
      <SmallParagraphTitle mt="20px">
        {t('legalDocumentsPages.privacyPolicy.collectionYourInformation.derivativeData.title')}
      </SmallParagraphTitle>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.privacyPolicy.collectionYourInformation.derivativeData.firstParagraph')}
      </ParagraphContent>
      <SmallParagraphTitle mt="20px">
        {t('legalDocumentsPages.privacyPolicy.collectionYourInformation.financialData.title')}
      </SmallParagraphTitle>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.privacyPolicy.collectionYourInformation.financialData.firstParagraphBeforeLink')}
        <StyledLink href="https://stripe.com/privacy">
          {t('legalDocumentsPages.privacyPolicy.collectionYourInformation.financialData.linkContent')}
        </StyledLink>
        {t('legalDocumentsPages.privacyPolicy.collectionYourInformation.financialData.firstParagraphAfterLink')}
      </ParagraphContent>
      <SmallParagraphTitle mt="20px">
        {t('legalDocumentsPages.privacyPolicy.collectionYourInformation.facebookPermissions.title')}
      </SmallParagraphTitle>
      <ParagraphContent mt="20px">
        {t(
          'legalDocumentsPages.privacyPolicy.collectionYourInformation.facebookPermissions.firstParagraphBeforeFirstLink',
        )}
        <StyledLink href="https://www.facebook.com/about/privacy/">
          {t('legalDocumentsPages.privacyPolicy.collectionYourInformation.facebookPermissions.firstLinkContent')}
        </StyledLink>
        {t(
          'legalDocumentsPages.privacyPolicy.collectionYourInformation.facebookPermissions.firstParagraphAfterFirstLink',
        )}
        <StyledLink href="https://developers.facebook.com/docs/permissions/reference">
          {t('legalDocumentsPages.privacyPolicy.collectionYourInformation.facebookPermissions.secondLinkContent')}
        </StyledLink>
        {t(
          'legalDocumentsPages.privacyPolicy.collectionYourInformation.facebookPermissions.firstParagraphAfterSecondLink',
        )}
      </ParagraphContent>
      <SmallParagraphTitle mt="20px">
        {t('legalDocumentsPages.privacyPolicy.collectionYourInformation.dataFromSocialNetworks.title')}
      </SmallParagraphTitle>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.privacyPolicy.collectionYourInformation.dataFromSocialNetworks.firstParagraph')}
      </ParagraphContent>
      <SmallParagraphTitle mt="20px">
        {t('legalDocumentsPages.privacyPolicy.collectionYourInformation.mobileDeviceData.title')}
      </SmallParagraphTitle>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.privacyPolicy.collectionYourInformation.mobileDeviceData.firstParagraph')}
      </ParagraphContent>
      <SmallParagraphTitle mt="20px">
        {t('legalDocumentsPages.privacyPolicy.collectionYourInformation.thirdPartyData.title')}
      </SmallParagraphTitle>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.privacyPolicy.collectionYourInformation.thirdPartyData.firstParagraph')}
      </ParagraphContent>
      <SmallParagraphTitle mt="20px">
        {t('legalDocumentsPages.privacyPolicy.collectionYourInformation.dataFromContests.title')}
      </SmallParagraphTitle>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.privacyPolicy.collectionYourInformation.dataFromContests.firstParagraph')}
      </ParagraphContent>
      <ParagraphTitle id="useYourInformation">
        {t('legalDocumentsPages.privacyPolicy.useYourInformation.title')}
      </ParagraphTitle>
      <ParagraphContent mt="30px">
        {t('legalDocumentsPages.privacyPolicy.useYourInformation.firstParagraph')}
      </ParagraphContent>
      <List>
        <ListItemWithOutPadding>
          <ParagraphContent>
            {t('legalDocumentsPages.privacyPolicy.useYourInformation.unorderedList.firstListItem')}
          </ParagraphContent>
        </ListItemWithOutPadding>
        <ListItem>
          <ParagraphContent>
            {t('legalDocumentsPages.privacyPolicy.useYourInformation.unorderedList.secondListItem')}
          </ParagraphContent>
        </ListItem>
        <ListItem>
          <ParagraphContent>
            {t('legalDocumentsPages.privacyPolicy.useYourInformation.unorderedList.thirdListItem')}
          </ParagraphContent>
        </ListItem>
        <ListItem>
          <ParagraphContent>
            {t('legalDocumentsPages.privacyPolicy.useYourInformation.unorderedList.fourthListItem')}
          </ParagraphContent>
        </ListItem>
        <ListItem>
          <ParagraphContent>
            {t('legalDocumentsPages.privacyPolicy.useYourInformation.unorderedList.fifthListItem')}
          </ParagraphContent>
        </ListItem>
        <ListItem>
          <ParagraphContent>
            {t('legalDocumentsPages.privacyPolicy.useYourInformation.unorderedList.sixthListItem')}
          </ParagraphContent>
        </ListItem>
        <ListItem>
          <ParagraphContent>
            {t('legalDocumentsPages.privacyPolicy.useYourInformation.unorderedList.seventhListItem')}
          </ParagraphContent>
        </ListItem>
        <ListItem>
          <ParagraphContent>
            {t('legalDocumentsPages.privacyPolicy.useYourInformation.unorderedList.eighthListItem')}
          </ParagraphContent>
        </ListItem>
        <ListItem>
          <ParagraphContent>
            {t('legalDocumentsPages.privacyPolicy.useYourInformation.unorderedList.ninthListItem')}
          </ParagraphContent>
        </ListItem>
        <ListItem>
          <ParagraphContent>
            {t('legalDocumentsPages.privacyPolicy.useYourInformation.unorderedList.tenthListItem')}
          </ParagraphContent>
        </ListItem>
        <ListItem>
          <ParagraphContent>
            {t('legalDocumentsPages.privacyPolicy.useYourInformation.unorderedList.eleventhListItem')}
          </ParagraphContent>
        </ListItem>
        <ListItem>
          <ParagraphContent>
            {t('legalDocumentsPages.privacyPolicy.useYourInformation.unorderedList.twelfthListItem')}
          </ParagraphContent>
        </ListItem>
        <ListItem>
          <ParagraphContent>
            {t('legalDocumentsPages.privacyPolicy.useYourInformation.unorderedList.thirteenthListItem')}
          </ParagraphContent>
        </ListItem>
        <ListItem>
          <ParagraphContent>
            {t('legalDocumentsPages.privacyPolicy.useYourInformation.unorderedList.fourteenthListItem')}
          </ParagraphContent>
        </ListItem>
        <ListItem>
          <ParagraphContent>
            {t('legalDocumentsPages.privacyPolicy.useYourInformation.unorderedList.fifteenthListItem')}
          </ParagraphContent>
        </ListItem>
        <ListItem>
          <ParagraphContent>
            {t('legalDocumentsPages.privacyPolicy.useYourInformation.unorderedList.sixteenthListItem')}
          </ParagraphContent>
        </ListItem>
        <ListItem>
          <ParagraphContent>
            {t('legalDocumentsPages.privacyPolicy.useYourInformation.unorderedList.seventeenthListItem')}
          </ParagraphContent>
        </ListItem>
        <ListItem>
          <ParagraphContent>
            {t('legalDocumentsPages.privacyPolicy.useYourInformation.unorderedList.eighteenthListItem')}
          </ParagraphContent>
        </ListItem>
        <ListItem>
          <ParagraphContent>
            {t('legalDocumentsPages.privacyPolicy.useYourInformation.unorderedList.nineteenthListItem')}
          </ParagraphContent>
        </ListItem>
        <ListItem>
          <ParagraphContent>
            {t('legalDocumentsPages.privacyPolicy.useYourInformation.unorderedList.twentiethListItem')}
          </ParagraphContent>
        </ListItem>
        <ListItem>
          <ParagraphContent>
            {t('legalDocumentsPages.privacyPolicy.useYourInformation.unorderedList.twentyFirstListItem')}
          </ParagraphContent>
        </ListItem>
      </List>
      <ParagraphTitle id="disclosureYourInformation">
        {t('legalDocumentsPages.privacyPolicy.disclosureYourInformation.title')}
      </ParagraphTitle>
      <ParagraphContent mt="30px">
        {t('legalDocumentsPages.privacyPolicy.disclosureYourInformation.firstParagraph')}
      </ParagraphContent>
      <SmallParagraphTitle mt="20px">
        {t('legalDocumentsPages.privacyPolicy.disclosureYourInformation.byLawOrToProtectRights.title')}
      </SmallParagraphTitle>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.privacyPolicy.disclosureYourInformation.byLawOrToProtectRights.firstParagraph')}
      </ParagraphContent>
      <SmallParagraphTitle mt="20px">
        {t('legalDocumentsPages.privacyPolicy.disclosureYourInformation.thirdPartyServiceProviders.title')}
      </SmallParagraphTitle>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.privacyPolicy.disclosureYourInformation.thirdPartyServiceProviders.firstParagraph')}
      </ParagraphContent>
      <SmallParagraphTitle mt="20px">
        {t('legalDocumentsPages.privacyPolicy.disclosureYourInformation.marketingCommunications.title')}
      </SmallParagraphTitle>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.privacyPolicy.disclosureYourInformation.marketingCommunications.firstParagraph')}
      </ParagraphContent>
      <SmallParagraphTitle mt="20px">
        {t('legalDocumentsPages.privacyPolicy.disclosureYourInformation.interactionsWithOtherUsers.title')}
      </SmallParagraphTitle>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.privacyPolicy.disclosureYourInformation.interactionsWithOtherUsers.firstParagraph')}
      </ParagraphContent>
      <SmallParagraphTitle mt="20px">
        {t('legalDocumentsPages.privacyPolicy.disclosureYourInformation.onlinePostings.title')}
      </SmallParagraphTitle>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.privacyPolicy.disclosureYourInformation.onlinePostings.firstParagraph')}
      </ParagraphContent>
      <SmallParagraphTitle mt="20px">
        {t('legalDocumentsPages.privacyPolicy.disclosureYourInformation.thirdPartyAdvertisers.title')}
      </SmallParagraphTitle>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.privacyPolicy.disclosureYourInformation.thirdPartyAdvertisers.firstParagraph')}
      </ParagraphContent>
      <SmallParagraphTitle mt="20px">
        {t('legalDocumentsPages.privacyPolicy.disclosureYourInformation.affiliates.title')}
      </SmallParagraphTitle>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.privacyPolicy.disclosureYourInformation.affiliates.firstParagraph')}
      </ParagraphContent>
      <SmallParagraphTitle mt="20px">
        {t('legalDocumentsPages.privacyPolicy.disclosureYourInformation.businessPartners.title')}
      </SmallParagraphTitle>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.privacyPolicy.disclosureYourInformation.businessPartners.firstParagraph')}
      </ParagraphContent>
      <SmallParagraphTitle mt="20px">
        {t('legalDocumentsPages.privacyPolicy.disclosureYourInformation.offerWall.title')}
      </SmallParagraphTitle>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.privacyPolicy.disclosureYourInformation.offerWall.firstParagraph')}
      </ParagraphContent>
      <SmallParagraphTitle mt="20px">
        {t('legalDocumentsPages.privacyPolicy.disclosureYourInformation.socialMediaContacts.title')}
      </SmallParagraphTitle>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.privacyPolicy.disclosureYourInformation.socialMediaContacts.firstParagraph')}
      </ParagraphContent>
      <SmallParagraphTitle mt="20px">
        {t('legalDocumentsPages.privacyPolicy.disclosureYourInformation.otherThirdParties.title')}
      </SmallParagraphTitle>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.privacyPolicy.disclosureYourInformation.otherThirdParties.firstParagraph')}
      </ParagraphContent>
      <SmallParagraphTitle mt="20px">
        {t('legalDocumentsPages.privacyPolicy.disclosureYourInformation.saleOrBankruptcy.title')}
      </SmallParagraphTitle>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.privacyPolicy.disclosureYourInformation.saleOrBankruptcy.firstParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.privacyPolicy.disclosureYourInformation.saleOrBankruptcy.secondParagraph')}
      </ParagraphContent>
      <ParagraphTitle id="trackingTechnologies">
        {t('legalDocumentsPages.privacyPolicy.trackingTechnologies.title')}
      </ParagraphTitle>
      <SmallParagraphTitle mt="30px">
        {t('legalDocumentsPages.privacyPolicy.trackingTechnologies.cookiesAndWebBeacons.title')}
      </SmallParagraphTitle>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.privacyPolicy.trackingTechnologies.cookiesAndWebBeacons.firstParagraph')}
      </ParagraphContent>
      <SmallParagraphTitle mt="20px">
        {t('legalDocumentsPages.privacyPolicy.trackingTechnologies.internetBasedAdvertising.title')}
      </SmallParagraphTitle>
      <ParagraphContent mt="20px">
        {t(
          'legalDocumentsPages.privacyPolicy.trackingTechnologies.internetBasedAdvertising.firstParagraphBeforeFirstLink',
        )}
        <StyledLink href="https://optout.networkadvertising.org/?c=1">
          {t('legalDocumentsPages.privacyPolicy.trackingTechnologies.internetBasedAdvertising.firstLinkContent')}
        </StyledLink>
        or
        <StyledLink href="https://optout.aboutads.info/?c=2&lang=EN">
          {t('legalDocumentsPages.privacyPolicy.trackingTechnologies.internetBasedAdvertising.secondLinkContent')}
        </StyledLink>
        .
      </ParagraphContent>
      <SmallParagraphTitle mt="20px">
        {t('legalDocumentsPages.privacyPolicy.trackingTechnologies.websiteAnalytics.title')}
      </SmallParagraphTitle>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.privacyPolicy.trackingTechnologies.websiteAnalytics.firstParagraphBeforeFirstLink')}
        <StyledLink href="https://support.google.com/analytics/answer/6004245?hl=en">
          {t('legalDocumentsPages.privacyPolicy.trackingTechnologies.websiteAnalytics.firstLinkContent')}
        </StyledLink>
        {t('legalDocumentsPages.privacyPolicy.trackingTechnologies.websiteAnalytics.firstParagraphAfterFirstLink')}
        <StyledLink href="https://optout.networkadvertising.org/?c=1">
          {t('legalDocumentsPages.privacyPolicy.trackingTechnologies.websiteAnalytics.secondLinkContent')}
        </StyledLink>
        {t('legalDocumentsPages.privacyPolicy.trackingTechnologies.websiteAnalytics.firstParagraphAfterSecondLink')}
        <StyledLink href="https://optout.aboutads.info/?c=2&lang=EN">
          {t('legalDocumentsPages.privacyPolicy.trackingTechnologies.websiteAnalytics.thirdLinkContent')}
        </StyledLink>
        .
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.privacyPolicy.trackingTechnologies.websiteAnalytics.secondParagraph')}
      </ParagraphContent>
      <ParagraphTitle id="thirdPartyWebsites">
        {t('legalDocumentsPages.privacyPolicy.thirdPartyWebsites.title')}
      </ParagraphTitle>
      <ParagraphContent mt="30px">
        {t('legalDocumentsPages.privacyPolicy.thirdPartyWebsites.firstParagraph')}
      </ParagraphContent>
      <ParagraphTitle id="securityOfYourInformation">
        {t('legalDocumentsPages.privacyPolicy.securityOfYourInformation.title')}
      </ParagraphTitle>
      <ParagraphContent mt="30px">
        {t('legalDocumentsPages.privacyPolicy.securityOfYourInformation.firstParagraph')}
      </ParagraphContent>
      <ParagraphTitle id="placeForChildren">
        {t('legalDocumentsPages.privacyPolicy.placeForChildren.title')}
      </ParagraphTitle>
      <ParagraphContent mt="30px">
        {t('legalDocumentsPages.privacyPolicy.placeForChildren.firstParagraph')}
      </ParagraphContent>
      <ParagraphTitle id="controlsForDoNotTrackFeatures">
        {t('legalDocumentsPages.privacyPolicy.controlsForDoNotTrackFeatures.title')}
      </ParagraphTitle>
      <ParagraphContent mt="30px">
        {t('legalDocumentsPages.privacyPolicy.controlsForDoNotTrackFeatures.firstParagraph')}
      </ParagraphContent>
      <ParagraphTitle id="optionsRegardingYourInformation">
        {t('legalDocumentsPages.privacyPolicy.optionsRegardingYourInformation.title')}
      </ParagraphTitle>
      <SmallParagraphTitle mt="30px">
        {t('legalDocumentsPages.privacyPolicy.optionsRegardingYourInformation.accountInformation.title')}
      </SmallParagraphTitle>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.privacyPolicy.optionsRegardingYourInformation.accountInformation.firstParagraph')}
      </ParagraphContent>
      <List>
        <ListItemWithOutPadding>
          <ParagraphContent>
            {t(
              'legalDocumentsPages.privacyPolicy.optionsRegardingYourInformation.accountInformation.unorderedList.firstListItem',
            )}
          </ParagraphContent>
        </ListItemWithOutPadding>
        <ListItem>
          <ParagraphContent>
            {t(
              'legalDocumentsPages.privacyPolicy.optionsRegardingYourInformation.accountInformation.unorderedList.secondListItem',
            )}
          </ParagraphContent>
        </ListItem>
      </List>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.privacyPolicy.optionsRegardingYourInformation.accountInformation.secondParagraph')}
      </ParagraphContent>
      <SmallParagraphTitle mt="20px">
        {t('legalDocumentsPages.privacyPolicy.optionsRegardingYourInformation.emailsAndCommunications.title')}
      </SmallParagraphTitle>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.privacyPolicy.optionsRegardingYourInformation.emailsAndCommunications.firstParagraph')}
      </ParagraphContent>
      <List>
        <ListItemWithOutPadding>
          <ParagraphContent>
            {t(
              'legalDocumentsPages.privacyPolicy.optionsRegardingYourInformation.emailsAndCommunications.unorderedList.firstListItem',
            )}
          </ParagraphContent>
        </ListItemWithOutPadding>
        <ListItem>
          <ParagraphContent>
            {t(
              'legalDocumentsPages.privacyPolicy.optionsRegardingYourInformation.emailsAndCommunications.unorderedList.secondListItem',
            )}
          </ParagraphContent>
        </ListItem>
        <ListItem>
          <ParagraphContent>
            {t(
              'legalDocumentsPages.privacyPolicy.optionsRegardingYourInformation.emailsAndCommunications.unorderedList.thirdListItem',
            )}
          </ParagraphContent>
        </ListItem>
      </List>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.privacyPolicy.optionsRegardingYourInformation.emailsAndCommunications.secondParagraph')}
      </ParagraphContent>
      <ParagraphTitle id="californiaPrivacyRights">
        {t('legalDocumentsPages.privacyPolicy.californiaPrivacyRights.title')}
      </ParagraphTitle>
      <ParagraphContent mt="30px">
        {t('legalDocumentsPages.privacyPolicy.californiaPrivacyRights.firstParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.privacyPolicy.californiaPrivacyRights.secondParagraph')}
      </ParagraphContent>
      <ParagraphTitle id="contactUsPrivacyPolicy">
        {t('legalDocumentsPages.privacyPolicy.contactUs.title')}
      </ParagraphTitle>
      <ParagraphContent mt="30px">{t('legalDocumentsPages.privacyPolicy.contactUs.firstParagraph')}</ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.privacyPolicy.contactUs.secondParagraph')}
        <StyledLink href="mailto:support@otomate.shop">
          {t('legalDocumentsPages.privacyPolicy.contactUs.linkContent')}
        </StyledLink>
      </ParagraphContent>
    </StyledSpatialBlock>
  );
};

export default PrivacyPolicyPage;
