import React from 'react';

import { useTranslation } from 'gatsby-plugin-react-i18next';

import {
  LastUpdatedText,
  PageTitle,
  ParagraphContent,
  ParagraphTitle,
  SmallParagraphTitle,
  StyledLink,
  StyledSpatialBlock,
} from './styled';

const TermsAndConditionsPage = () => {
  const { t } = useTranslation();

  return (
    <StyledSpatialBlock id="terms-and-conditions">
      <PageTitle>{t('legalDocumentsPages.termsAndConditions.title')}</PageTitle>
      <LastUpdatedText>{t('legalDocumentsPages.lastUpdateInfo')}</LastUpdatedText>
      <ParagraphTitle id="agreementToTerms">
        {t('legalDocumentsPages.termsAndConditions.agreementToTerms.title')}
      </ParagraphTitle>
      <ParagraphContent mt="30px">
        {t('legalDocumentsPages.termsAndConditions.agreementToTerms.firstParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.agreementToTerms.secondParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.agreementToTerms.thirdParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.agreementToTerms.fourthParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.agreementToTerms.fifthParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.agreementToTerms.sixthParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.agreementToTerms.seventhParagraph')}
      </ParagraphContent>
      <ParagraphTitle id="intellectualPropertyRights">
        {t('legalDocumentsPages.termsAndConditions.intellectualPropertyRights.title')}
      </ParagraphTitle>
      <ParagraphContent mt="30px">
        {t('legalDocumentsPages.termsAndConditions.intellectualPropertyRights.firstParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.intellectualPropertyRights.secondParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.intellectualPropertyRights.thirdParagraph')}
      </ParagraphContent>
      <ParagraphTitle id="userRepresentations">
        {t('legalDocumentsPages.termsAndConditions.userRepresentations.title')}
      </ParagraphTitle>
      <ParagraphContent mt="30px">
        {t('legalDocumentsPages.termsAndConditions.userRepresentations.firstParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.userRepresentations.secondParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.userRepresentations.thirdParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.userRepresentations.fourthParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.userRepresentations.fifthParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.userRepresentations.sixthParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.userRepresentations.seventhParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.userRepresentations.eighthParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.userRepresentations.ninthParagraph')}
      </ParagraphContent>
      <ParagraphTitle id="userRegistration">
        {t('legalDocumentsPages.termsAndConditions.userRegistration.title')}
      </ParagraphTitle>
      <ParagraphContent mt="30px">
        {t('legalDocumentsPages.termsAndConditions.userRegistration.firstParagraph')}
      </ParagraphContent>
      <ParagraphTitle id="prohibitedActivities">
        {t('legalDocumentsPages.termsAndConditions.prohibitedActivities.title')}
      </ParagraphTitle>
      <ParagraphContent mt="30px">
        {t('legalDocumentsPages.termsAndConditions.prohibitedActivities.firstParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.prohibitedActivities.secondParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.prohibitedActivities.thirdParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.prohibitedActivities.fourthParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.prohibitedActivities.fifthParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.prohibitedActivities.sixthParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.prohibitedActivities.seventhParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.prohibitedActivities.eighthParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.prohibitedActivities.ninthParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.prohibitedActivities.tenthParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.prohibitedActivities.eleventhParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.prohibitedActivities.twelfthParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.prohibitedActivities.thirteenthParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.prohibitedActivities.fourteenthParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.prohibitedActivities.fifteenthParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.prohibitedActivities.sixteenthParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.prohibitedActivities.seventeenthParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.prohibitedActivities.eighteenthParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.prohibitedActivities.nineteenthParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.prohibitedActivities.twentiethParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.prohibitedActivities.twentyFirstParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.prohibitedActivities.twentySecondParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.prohibitedActivities.twentyThirdParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.prohibitedActivities.twentyFourthParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.prohibitedActivities.twentyFifthParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.prohibitedActivities.twentySixthParagraph')}
      </ParagraphContent>
      <ParagraphTitle id="userGeneratedContributions">
        {t('legalDocumentsPages.termsAndConditions.userGeneratedContributions.title')}
      </ParagraphTitle>
      <ParagraphContent mt="30px">
        {t('legalDocumentsPages.termsAndConditions.userGeneratedContributions.firstParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.userGeneratedContributions.secondParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.userGeneratedContributions.thirdParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.userGeneratedContributions.fourthParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.userGeneratedContributions.fifthParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.userGeneratedContributions.sixthParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.userGeneratedContributions.seventhParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.userGeneratedContributions.eighthParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.userGeneratedContributions.ninthParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.userGeneratedContributions.tenthParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.userGeneratedContributions.eleventhParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.userGeneratedContributions.twelfthParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.userGeneratedContributions.thirteenthParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.userGeneratedContributions.fourteenthParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.userGeneratedContributions.fifteenthParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.userGeneratedContributions.sixteenthParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.userGeneratedContributions.seventeenthParagraph')}
      </ParagraphContent>
      <ParagraphTitle id="contributionLicense">
        {t('legalDocumentsPages.termsAndConditions.contributionLicense.title')}
      </ParagraphTitle>
      <ParagraphContent mt="30px">
        {t('legalDocumentsPages.termsAndConditions.contributionLicense.firstParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.contributionLicense.secondParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.contributionLicense.thirdParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.contributionLicense.fourthParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.contributionLicense.fifthParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.contributionLicense.sixthParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.contributionLicense.seventhParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.contributionLicense.eighthParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.contributionLicense.ninthParagraph')}
      </ParagraphContent>
      <ParagraphTitle id="guidelinesForReviews">
        {t('legalDocumentsPages.termsAndConditions.guidelinesForReviews.title')}
      </ParagraphTitle>
      <ParagraphContent mt="30px">
        {t('legalDocumentsPages.termsAndConditions.guidelinesForReviews.firstParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.guidelinesForReviews.secondParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.guidelinesForReviews.thirdParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.guidelinesForReviews.fourthParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.guidelinesForReviews.fifthParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.guidelinesForReviews.sixthParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.guidelinesForReviews.seventhParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.guidelinesForReviews.eighthParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.guidelinesForReviews.ninthParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.guidelinesForReviews.tenthParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.guidelinesForReviews.eleventhParagraph')}
      </ParagraphContent>
      <ParagraphTitle id="socialMedia">{t('legalDocumentsPages.termsAndConditions.socialMedia.title')}</ParagraphTitle>
      <ParagraphContent mt="30px">
        {t('legalDocumentsPages.termsAndConditions.socialMedia.firstParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.socialMedia.secondParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.socialMedia.thirdParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.socialMedia.fourthParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.socialMedia.fifthParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.socialMedia.sixthParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.socialMedia.seventhParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.socialMedia.eighthParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.socialMedia.ninthParagraph')}
      </ParagraphContent>
      <ParagraphTitle id="submissions">{t('legalDocumentsPages.termsAndConditions.submissions.title')}</ParagraphTitle>
      <ParagraphContent mt="30px">
        {t('legalDocumentsPages.termsAndConditions.submissions.firstParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.submissions.secondParagraph')}
      </ParagraphContent>
      <ParagraphTitle id="thirdPartyWebsites">
        {t('legalDocumentsPages.termsAndConditions.thirdPartyWebsites.title')}
      </ParagraphTitle>
      <ParagraphContent mt="30px">
        {t('legalDocumentsPages.termsAndConditions.thirdPartyWebsites.firstParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.thirdPartyWebsites.secondParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.thirdPartyWebsites.thirdParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.thirdPartyWebsites.fourthParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.thirdPartyWebsites.fifthParagraph')}
      </ParagraphContent>
      <ParagraphTitle id="advertisers">{t('legalDocumentsPages.termsAndConditions.advertisers.title')}</ParagraphTitle>
      <ParagraphContent mt="30px">
        {t('legalDocumentsPages.termsAndConditions.advertisers.firstParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.advertisers.secondParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.advertisers.thirdParagraph')}
      </ParagraphContent>
      <ParagraphTitle id="siteManagement">
        {t('legalDocumentsPages.termsAndConditions.siteManagement.title')}
      </ParagraphTitle>
      <ParagraphContent mt="30px">
        {t('legalDocumentsPages.termsAndConditions.siteManagement.firstParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.siteManagement.secondParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.siteManagement.thirdParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.siteManagement.fourthParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.siteManagement.fifthParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.siteManagement.sixthParagraph')}
      </ParagraphContent>
      <ParagraphTitle id="privacyPolicy">
        {t('legalDocumentsPages.termsAndConditions.privacyPolicy.title')}
      </ParagraphTitle>
      <ParagraphContent mt="30px">
        {t('legalDocumentsPages.termsAndConditions.privacyPolicy.firstParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.privacyPolicy.secondParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.privacyPolicy.thirdParagraph')}
      </ParagraphContent>
      <ParagraphTitle id="DMCA">{t('legalDocumentsPages.termsAndConditions.DMCA.titleFirstPart')}</ParagraphTitle>
      <ParagraphTitle>{t('legalDocumentsPages.termsAndConditions.DMCA.titleSecondPart')}</ParagraphTitle>
      <SmallParagraphTitle mt="30px">
        {t('legalDocumentsPages.termsAndConditions.DMCA.notifications.title')}
      </SmallParagraphTitle>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.DMCA.notifications.firstParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.DMCA.notifications.secondParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.DMCA.notifications.thirdParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.DMCA.notifications.fourthParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.DMCA.notifications.fifthParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.DMCA.notifications.sixthParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.DMCA.notifications.seventhParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.DMCA.notifications.eighthParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.DMCA.notifications.ninthParagraph')}
      </ParagraphContent>
      <SmallParagraphTitle mt="20px">
        {t('legalDocumentsPages.termsAndConditions.DMCA.counterNotification.title')}
      </SmallParagraphTitle>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.DMCA.counterNotification.firstParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.DMCA.counterNotification.secondParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.DMCA.counterNotification.thirdParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.DMCA.counterNotification.fourthParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.DMCA.counterNotification.fifthParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.DMCA.counterNotification.sixthParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.DMCA.counterNotification.seventhParagraph')}
      </ParagraphContent>
      <ParagraphContent ml="10px" mt="20px">
        {t('legalDocumentsPages.termsAndConditions.DMCA.counterNotification.eighthParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.DMCA.counterNotification.ninthParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.DMCA.counterNotification.tenthParagraph')}
      </ParagraphContent>
      <ParagraphTitle id="copyrightInfringements">
        {t('legalDocumentsPages.termsAndConditions.copyrightInfringements.title')}
      </ParagraphTitle>
      <ParagraphContent mt="30px">
        {t('legalDocumentsPages.termsAndConditions.copyrightInfringements.firstParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.copyrightInfringements.secondParagraph')}
      </ParagraphContent>
      <ParagraphTitle id="termAndTermination">
        {t('legalDocumentsPages.termsAndConditions.termAndTermination.title')}
      </ParagraphTitle>
      <ParagraphContent mt="30px">
        {t('legalDocumentsPages.termsAndConditions.termAndTermination.firstParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.termAndTermination.secondParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.termAndTermination.thirdParagraph')}
      </ParagraphContent>
      <ParagraphTitle id="modificationsAndInterruptions">
        {t('legalDocumentsPages.termsAndConditions.modificationsAndInterruptions.title')}
      </ParagraphTitle>
      <ParagraphContent mt="30px">
        {t('legalDocumentsPages.termsAndConditions.modificationsAndInterruptions.firstParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.modificationsAndInterruptions.secondParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.modificationsAndInterruptions.thirdParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.modificationsAndInterruptions.fourthParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.modificationsAndInterruptions.fifthParagraph')}
      </ParagraphContent>
      <ParagraphTitle id="governingLaw">
        {t('legalDocumentsPages.termsAndConditions.governingLaw.title')}
      </ParagraphTitle>
      <ParagraphContent mt="30px">
        {t('legalDocumentsPages.termsAndConditions.governingLaw.firstParagraph')}
      </ParagraphContent>
      <ParagraphTitle id="disputeResolution">
        {t('legalDocumentsPages.termsAndConditions.disputeResolution.title')}
      </ParagraphTitle>
      <SmallParagraphTitle mt="30px">
        {t('legalDocumentsPages.termsAndConditions.disputeResolution.firstOption.title')}
      </SmallParagraphTitle>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.disputeResolution.firstOption.firstParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.disputeResolution.firstOption.secondParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.disputeResolution.firstOption.thirdParagraph')}
      </ParagraphContent>
      <SmallParagraphTitle mt="20px">
        {t('legalDocumentsPages.termsAndConditions.disputeResolution.informalNegotiations.title')}
      </SmallParagraphTitle>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.disputeResolution.informalNegotiations.firstParagraph')}
      </ParagraphContent>
      <SmallParagraphTitle mt="20px">
        {t('legalDocumentsPages.termsAndConditions.disputeResolution.bindingArbitration.title')}
      </SmallParagraphTitle>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.disputeResolution.bindingArbitration.firstParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.disputeResolution.bindingArbitration.secondParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.disputeResolution.bindingArbitration.thirdParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.disputeResolution.bindingArbitration.fourthParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.disputeResolution.bindingArbitration.fifthParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.disputeResolution.bindingArbitration.sixthParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.disputeResolution.bindingArbitration.seventhParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.disputeResolution.bindingArbitration.eighthParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.disputeResolution.bindingArbitration.ninthParagraph')}
      </ParagraphContent>
      <SmallParagraphTitle mt="20px">
        {t('legalDocumentsPages.termsAndConditions.disputeResolution.restrictions.title')}
      </SmallParagraphTitle>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.disputeResolution.restrictions.firstParagraph')}
      </ParagraphContent>
      <SmallParagraphTitle mt="20px">
        {t('legalDocumentsPages.termsAndConditions.disputeResolution.exceptions.title')}
      </SmallParagraphTitle>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.disputeResolution.exceptions.firstParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.disputeResolution.exceptions.secondParagraph')}
      </ParagraphContent>
      <ParagraphTitle id="corrections">{t('legalDocumentsPages.termsAndConditions.corrections.title')}</ParagraphTitle>
      <ParagraphContent mt="30px">
        {t('legalDocumentsPages.termsAndConditions.corrections.firstParagraph')}
      </ParagraphContent>
      <ParagraphTitle id="disclaimer">{t('legalDocumentsPages.termsAndConditions.disclaimer.title')}</ParagraphTitle>
      <ParagraphContent mt="30px">
        {t('legalDocumentsPages.termsAndConditions.disclaimer.firstParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.disclaimer.secondParagraph')}
      </ParagraphContent>
      <ParagraphTitle id="limitations">{t('legalDocumentsPages.termsAndConditions.limitations.title')}</ParagraphTitle>
      <ParagraphContent mt="30px">
        {t('legalDocumentsPages.termsAndConditions.limitations.firstParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.limitations.secondParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.limitations.thirdParagraph')}
      </ParagraphContent>
      <ParagraphTitle id="indemnification">
        {t('legalDocumentsPages.termsAndConditions.indemnification.title')}
      </ParagraphTitle>
      <ParagraphContent mt="30px">
        {t('legalDocumentsPages.termsAndConditions.indemnification.firstParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.indemnification.secondParagraph')}
      </ParagraphContent>
      <ParagraphTitle id="userData">{t('legalDocumentsPages.termsAndConditions.userData.title')}</ParagraphTitle>
      <ParagraphContent mt="30px">
        {t('legalDocumentsPages.termsAndConditions.userData.firstParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.userData.secondParagraph')}
      </ParagraphContent>
      <ParagraphTitle id="communications">
        {t('legalDocumentsPages.termsAndConditions.communications.titleFirstPart')}
      </ParagraphTitle>
      <ParagraphTitle>{t('legalDocumentsPages.termsAndConditions.communications.titleSecondPart')}</ParagraphTitle>
      <ParagraphContent mt="30px">
        {t('legalDocumentsPages.termsAndConditions.communications.firstParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.communications.secondParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.communications.thirdParagraph')}
      </ParagraphContent>
      <ParagraphTitle id="userAndResidents">
        {t('legalDocumentsPages.termsAndConditions.userAndResidents.title')}
      </ParagraphTitle>
      <ParagraphContent mt="30px">
        {t('legalDocumentsPages.termsAndConditions.userAndResidents.firstParagraph')}
      </ParagraphContent>
      <ParagraphTitle id="miscellaneous">
        {t('legalDocumentsPages.termsAndConditions.miscellaneous.title')}
      </ParagraphTitle>
      <ParagraphContent mt="30px">
        {t('legalDocumentsPages.termsAndConditions.miscellaneous.firstParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.miscellaneous.secondParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.miscellaneous.thirdParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.miscellaneous.fourthParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.miscellaneous.fifthParagraph')}
      </ParagraphContent>
      <ParagraphTitle id="contactUsTermsAndConditions">
        {t('legalDocumentsPages.termsAndConditions.contactUs.title')}
      </ParagraphTitle>
      <ParagraphContent mt="30px">
        {t('legalDocumentsPages.termsAndConditions.contactUs.firstParagraph')}
      </ParagraphContent>
      <ParagraphContent mt="20px">
        {t('legalDocumentsPages.termsAndConditions.contactUs.secondParagraph')}
        <StyledLink href="mailto:support@otomate.shop">
          {t('legalDocumentsPages.termsAndConditions.contactUs.linkContent')}
        </StyledLink>
      </ParagraphContent>
    </StyledSpatialBlock>
  );
};

export default TermsAndConditionsPage;
